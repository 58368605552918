import { graphql } from 'gatsby';
import React, { useMemo } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import BackgroundImage from 'gatsby-background-image';

import Button from '@atoms/Button/Button';
import Section from '@atoms/Section/Section';
import Title from '@atoms/Title/Title';
import TitleGroup from '@atoms/TitleGroup/TitleGroup';
import Footer from '@components/Footer/Footer';
import Seo from '@components/seo';
import OrderedCard from '@components/Card/OrderedCard';

import { Grid } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { BsFileEarmarkBreak } from 'react-icons/bs';
import { FaHandHoldingHeart } from 'react-icons/fa';
import { MdMoneyOff } from 'react-icons/md';
import { TiStarOutline } from 'react-icons/ti';
import { HiOutlineUserGroup } from 'react-icons/hi';
import AvatarProvidersSlider from '@components/AvatarProvidersSlider';
import CardWithIcon from '@components/Card/CardWithIcon';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import KeyFeaturesSlider from '@components/KeyFeaturesSlider';
import Layout from '@components/layout';
import RequirementToJoin from '@components/RequirementToJoin';
import SingleAccordion from '@components/SingleAccordion/SingleAccordion';
import Stepper from '@components/Stepper';
import SBAPCollaborationBox from '../../CMSComponents/SBAPCollaborationBox';
import { ProviderList } from '@components/CMSComponents';
import TherapyPricingShortInfo from '@components/TherapyPricingShortInfo';
import { useLocale, useFetchFaq } from '@hooks';
import { getOnboardingLink } from '@helpers/linkHelpers';
import TherapistFeatures from '../../../landingPage/TherapistFeatures';
import './ForTherapists.scss';
import {
    TheAepsyWayLink,
    SelfEmploymentLink,
} from '@components/CMSComponents/links';
import { TheAepsyWayAnalytics } from '@components/CMSPages/TheAepsyWay';
import Link from '@components/Link';
import {
    IconLockClosedOutline,
    IconShieldCheckmarkOutline,
} from '@atoms/Icon/list';
import { CgSupport } from 'react-icons/cg';
import {
    IconCalendarOutline,
    IconChatbubblesOutline,
    IconCommunity,
    IconCreditCard,
    IconHeartOutline,
    IconLocationSharp,
    IconNotificationsOutline,
    IconPersonOutline,
    IconPhone,
    IconPrescription,
    IconVideocamOutline,
} from '@atoms/Icon/list';
import { UserLang } from '@constants/common';
import { withLocale } from '@hocs';
import { useIsMobile } from '@hooks/useIsDevice';

export const query = graphql`
    fragment FaqField on AEPSYCMS_Faq {
        qar(pagination: { limit: 99 }) {
            question
            answer
        }
    }
    fragment ForTherapistField on AEPSYCMS_ForTherapist {
        providersSlider {
            ...ProvidersSlider
        }
        sbap_colllaboration {
            ... on AEPSYCMS_ComponentCommonOrderedCard {
                title
                subtitle
                content
                buttons {
                    label
                }
                images {
                    media
                    src {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        pricingCardImage: file(
            relativePath: { eq: "approach/card-green-background-desktop.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        pricingCardMobileImage: file(
            relativePath: { eq: "approach/card-green-background-mobile.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        aepsyCms {
            forTherapistDE: forTherapist(locale: "de-CH") {
                data {
                    attributes {
                        ...ForTherapistField
                    }
                }
            }
            forTherapistEN: forTherapist(locale: "en") {
                data {
                    attributes {
                        ...ForTherapistField
                    }
                }
            }
            forTherapistFR: forTherapist(locale: "fr-CH") {
                data {
                    attributes {
                        ...ForTherapistField
                    }
                }
            }
            forTherapistIT: forTherapist(locale: "it") {
                data {
                    attributes {
                        ...ForTherapistField
                    }
                }
            }
            faqsDE: faqs(
                locale: "de-CH"
                filters: { key: { eq: "FOR_PSYCHOLOGIST" } }
            ) {
                data {
                    attributes {
                        ...FaqField
                    }
                }
            }
            faqsEN: faqs(
                locale: "en"
                filters: { key: { eq: "FOR_PSYCHOLOGIST" } }
            ) {
                data {
                    attributes {
                        ...FaqField
                    }
                }
            }
            faqsFR: faqs(
                locale: "fr-CH"
                filters: { key: { eq: "FOR_PSYCHOLOGIST" } }
            ) {
                data {
                    attributes {
                        ...FaqField
                    }
                }
            }
            faqsIT: faqs(
                locale: "it"
                filters: { key: { eq: "FOR_PSYCHOLOGIST" } }
            ) {
                data {
                    attributes {
                        ...FaqField
                    }
                }
            }
        }
        theAepsyWayDesktopImage: file(
            relativePath: {
                eq: "the-aepsy-way/visual-woman-walking-away-desktop.png"
            }
        ) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 1440) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        theAepsyWayMobileImage: file(
            relativePath: {
                eq: "the-aepsy-way/visual-woman-walking-away-mobile.png"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        forTherapistsDesktopHero: file(
            relativePath: {
                eq: "for-therapists/header-for-provider-desktop.jpg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        forTherapistsMobileHero: file(
            relativePath: {
                eq: "for-therapists/header-for-provider-mobile.jpg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 450, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        selfEmploymentDesktopImage: file(
            relativePath: {
                eq: "self-employment/visual-man-computer-desktop.png"
            }
        ) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 1440) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        selfEmploymentMobileImage: file(
            relativePath: {
                eq: "self-employment/visual-man-computer-mobile.png"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

const ForTherapistPage: React.FC<any> = ({ location, data }) => {
    const { t } = useTranslation();
    const { currentLanguage } = useLocale();
    const { faq } = useFetchFaq({
        query: data,
    });

    const forTherapist = useMemo(() => {
        if (!data?.aepsyCms) {
            return { forTherapist: null, faq: null };
        }
        const {
            aepsyCms: {
                forTherapistDE,
                forTherapistEN,
                forTherapistFR,
                forTherapistIT,
            },
        } = data;
        switch (currentLanguage) {
            case UserLang.English:
                return forTherapistEN.data.attributes;
            case UserLang.French:
                return forTherapistFR.data.attributes;
            case UserLang.Italian:
                return forTherapistIT.data.attributes;
            case UserLang.German:
            default:
                return forTherapistDE.data.attributes;
        }
    }, [data, currentLanguage]);

    const KEY_USP = [
        {
            title: t('for.providers.key.usp.get.clients.title'),
            subtitle: t('for.providers.key.usp.get.clients.subtitle'),
            icon: <FaHandHoldingHeart />,
        },
        {
            title: t('for.providers.key.usp.multiple.offers.title'),
            subtitle: t('for.providers.key.usp.multiple.offers.subtitle'),
            icon: <HiOutlineUserGroup />,
        },
        {
            title: t('for.providers.key.usp.no.admin.title'),
            subtitle: t('for.providers.key.usp.no.admin.subtitle'),
            icon: <BsFileEarmarkBreak />,
        },
        {
            title: t('for.providers.key.usp.great.matching.title'),
            subtitle: t('for.providers.key.usp.great.matching.subtitle'),
            icon: <TiStarOutline />,
        },
        {
            title: t('for.providers.key.usp.control.of.workload.title'),
            subtitle: t('for.providers.key.usp.control.of.workload.subtitle'),
            icon: <IconShieldCheckmarkOutline />,
        },
        {
            title: t('for.providers.key.usp.community.title'),
            subtitle: t('for.providers.key.usp.community.subtitle'),
            icon: <CgSupport />,
        },
        {
            title: t('for.providers.key.usp.security.title'),
            subtitle: t('for.providers.key.usp.security.subtitle'),
            icon: <IconLockClosedOutline />,
        },
        {
            title: t('for.providers.key.usp.no.fix.cost.title'),
            subtitle: t('for.providers.key.usp.no.fix.cost.subtitle'),
            icon: <MdMoneyOff />,
        },
    ];

    const KEY_FEATURES = [
        {
            title: t('for.providers.feature.list.get.client'),
            icon: <IconHeartOutline />,
        },
        {
            title: t('for.providers.feature.list.personal.page'),
            icon: <IconPersonOutline />,
        },
        {
            title: t('for.providers.feature.list.online.payment'),
            icon: <IconCreditCard />,
        },
        {
            title: t('for.providers.feature.list.direct.booking'),
            icon: <IconCalendarOutline />,
        },
        {
            title: t('for.providers.feature.list.online.in.person'),
            icon: <IconLocationSharp />,
        },
        {
            title: t('for.providers.feature.list.unlimited.chat'),
            icon: <IconChatbubblesOutline />,
        },
        {
            title: t('for.providers.feature.list.video.call'),
            icon: <IconVideocamOutline />,
        },
        {
            title: t('for.providers.feature.list.insurance.receipt'),
            icon: <IconPrescription />,
        },
        {
            title: t('for.providers.feature.list.reminders'),
            icon: <IconNotificationsOutline />,
        },
        {
            title: t('for.providers.feature.list.mobile.app'),
            icon: <IconPhone />,
        },
        {
            title: t('for.providers.feature.list.community'),
            icon: <IconCommunity />,
        },
        {
            title: t('for.providers.feature.list.secure'),
            icon: <IconLockClosedOutline />,
        },
    ];

    if (!forTherapist) return <></>;

    const {
        providersSlider,
        sbap_colllaboration: sbapColllaboration,
    } = forTherapist;

    const sbap = sbapColllaboration ? sbapColllaboration[0] : undefined;

    const isMobile = useIsMobile();
    return (
        <Layout>
            <Seo
                title={t('for.providers.seo.title')}
                description={t('for.providers.seo.description')}
            />
            <div className="ForTherapistPage">
                <div className="Header--wrapper">
                    <div className="green-gradient-background"></div>

                    <div className="Header--VideoWrapper">
                        <HeaderNav
                            location={location.pathname}
                            theme="white"
                            signUpAsProviderShown
                        />
                        <BackgroundImage
                            Tag="section"
                            id="media-test"
                            className="ForTherapistPage--bg"
                            fluid={isMobile ? data.forTherapistsMobileHero.childImageSharp.fluid : data.forTherapistsDesktopHero.childImageSharp.fluid}
                        />
                        <div className="Header--title">
                            <Title
                                tag="h1"
                                font="alt"
                                size="xxl"
                                theme="white"
                                align="center"
                            >
                                {t('for.providers.hero.header.title')}
                            </Title>
                            <Title
                                tag="h2"
                                theme="white"
                                size="s"
                                align="center"
                                noMargin
                            >
                                {t(
                                    'for.providers.hero.header.subtitle.primary'
                                )}
                            </Title>

                            <Section spacingTop="xs">
                                <AnchorLink
                                    offset={() => 120}
                                    href="#provider-signup"
                                >
                                    <Button
                                        label={t(
                                            'join.as.psychologist.cta.label'
                                        )}
                                        align="center"
                                        theme="pink-gradient"
                                        isMobileFullsize
                                    />
                                </AnchorLink>
                                <AnchorLink
                                    offset={() => 120}
                                    href="#learn-more"
                                >
                                    <Button
                                        theme="white"
                                        label={t('learn.more')}
                                        align="center"
                                        variant="naked"
                                    />
                                </AnchorLink>
                            </Section>
                        </div>
                    </div>
                </div>
                <div className="global_theme-green">
                    <Section theme="dark">
                        <div id="learn-more">
                            <KeyFeaturesSlider list={KEY_USP} />
                        </div>
                    </Section>
                    <Section
                        theme="dark"
                        container="large"
                        spacingTop="m"
                        spacingBottom="s"
                    >
                        <Title tag="p" size="xl" theme="white">
                            <Trans
                                i18nKey={'for.providers.key.statement.quote'}
                                components={{ br: <br /> }}
                            />
                        </Title>
                        <Section spacingTop="s">
                            <TheAepsyWayLink
                                buttonProps={{
                                    theme: 'pink-gradient',
                                }}
                                label="for.providers.button.to.aepsy.way"
                            />
                        </Section>
                    </Section>

                    <Section theme="dark">
                        {forTherapist && (
                            <ProviderList
                                variant="detail"
                                theme="white"
                                {...providersSlider}
                            />
                        )}
                        <Section container="short" spacing="m">
                            <TitleGroup
                                theme="white"
                                title={t(
                                    'for.providers.community.section.title'
                                )}
                                subtitle={t(
                                    'for.providers.community.section.subtitle'
                                )}
                                align="desktopCenter"
                            />
                        </Section>

                        <Section spacingBottom="m">
                            <AvatarProvidersSlider
                                therapyOnly
                                hasViewMore
                                theme="white"
                            />
                            <Section spacingTop="s">
                                <Link to="/providers">
                                    <Button
                                        label={t(
                                            'button.view.all.psychologists'
                                        )}
                                        align="center"
                                        size="l"
                                        theme="pink-gradient"
                                    />
                                </Link>
                            </Section>
                        </Section>
                    </Section>

                    <Section container="short" spacing="m">
                        <TitleGroup
                            title={t(
                                'for.providers.highlighted.features.section.title'
                            )}
                            subtitle={t(
                                'for.providers.highlighted.features.section.subtitle'
                            )}
                            align="desktopCenter"
                        />
                    </Section>

                    <Section spacingBottom="l" theme="light" container="large">
                        <TherapistFeatures />
                    </Section>

                    <Section theme="soft" container="large" spacingBottom="xs">
                        <div className="TheAepsyWayAnalytics">
                            <TheAepsyWayAnalytics />
                        </div>
                    </Section>
                    <div className="TheApesyWayCallout">
                        <Section container="short">
                            <OrderedCard
                                title={t('the.aepsy.way.call.out.title')}
                                subtitle={t('the.aepsy.way.call.out.subtitle')}
                                images={[
                                    {
                                        media: 'desktop',
                                        src: {
                                            data: {
                                                attributes: {
                                                    url:
                                                        data
                                                            .theAepsyWayDesktopImage
                                                            .childImageSharp
                                                            .fluid.src,
                                                },
                                            },
                                        },
                                    },
                                    {
                                        media: 'mobile',
                                        src: {
                                            data: {
                                                attributes: {
                                                    url:
                                                        data
                                                            .theAepsyWayMobileImage
                                                            .childImageSharp
                                                            .fluid.src,
                                                },
                                            },
                                        },
                                    },
                                ]}
                            >
                                <Section spacingTop="s">
                                    <TheAepsyWayLink
                                        buttonProps={{}}
                                        target="_blank"
                                    />
                                </Section>
                            </OrderedCard>
                        </Section>
                    </div>

                    <Section container="short" spacing="m">
                        <TitleGroup
                            title={t(
                                'for.providers.feature.list.section.title'
                            )}
                            subtitle={t(
                                'for.providers.feature.list.section.subtitle'
                            )}
                            align="desktopCenter"
                        />
                    </Section>

                    <Section container="large" spacingBottom="m">
                        <Grid container spacing={1}>
                            {KEY_FEATURES.map((item: any) => (
                                <Grid item xs={6} sm={6} md={3}>
                                    <CardWithIcon
                                        title={item.title}
                                        icon={item.icon}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        <Section spacingTop="xs">
                            <a
                                href={getOnboardingLink(currentLanguage)}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button
                                    label={t('join.as.psychologist.cta.label')}
                                    iconArrow
                                    variant="naked"
                                    align="center"
                                />
                            </a>
                        </Section>
                    </Section>

                    <Section container="short" spacingBottom="m">
                        <OrderedCard
                            title={t('self.employment.call.out.title')}
                            subtitle={t('self.employment.call.out.subtitle')}
                            images={[
                                {
                                    media: 'desktop',
                                    src: {
                                        data: {
                                            attributes: {
                                                url:
                                                    data
                                                        .selfEmploymentDesktopImage
                                                        .childImageSharp.fluid
                                                        .src,
                                            },
                                        },
                                    },
                                },
                                {
                                    media: 'mobile',
                                    src: {
                                        data: {
                                            attributes: {
                                                url:
                                                    data
                                                        .selfEmploymentMobileImage
                                                        .childImageSharp.fluid
                                                        .src,
                                            },
                                        },
                                    },
                                },
                            ]}
                        >
                            <Section spacingTop="s">
                                <SelfEmploymentLink
                                    buttonProps={{}}
                                    target="_blank"
                                />
                            </Section>
                        </OrderedCard>
                    </Section>

                    {sbap && (
                        <Section container="short" spacingBottom="l">
                            <SBAPCollaborationBox {...sbap} />
                        </Section>
                    )}

                    <Section spacingBottom="m">
                        <TherapyPricingShortInfo 
                            imageUrlSharp={data.pricingCardImage}
                            mobileImageUrlSharp={data.pricingCardMobileImage}
                        />
                    </Section>

                    <Section container="short" spacingBottom="m" spacingTop="s">
                        <TitleGroup
                            title={t('for.providers.join.aepsy.section.title')}
                            subtitle={t(
                                'for.providers.join.aepsy.section.subtitle'
                            )}
                            align="desktopCenter"
                        />
                    </Section>

                    <Stepper
                        list={[
                            {
                                icon: '👋',
                                title: t(
                                    'for.providers.join.aepsy.section.step.one.title'
                                ),
                                subtitle: t(
                                    'for.providers.join.aepsy.section.step.one.subtitle'
                                ),
                                buttonLabel: t(
                                    'join.as.psychologist.cta.label'
                                ),
                                url: getOnboardingLink(currentLanguage),
                            },
                            {
                                icon: '🗓',
                                title: t(
                                    'for.providers.join.aepsy.section.step.two.title'
                                ),
                                subtitle: t(
                                    'for.providers.join.aepsy.section.step.two.subtitle'
                                ),
                            },
                            {
                                icon: '📞',
                                title: t(
                                    'for.providers.join.aepsy.section.step.three.title'
                                ),
                                subtitle: t(
                                    'for.providers.join.aepsy.section.step.three.subtitle'
                                ),
                            },
                            {
                                icon: '👀',
                                title: t(
                                    'for.providers.join.aepsy.section.step.four.title'
                                ),
                                subtitle: t(
                                    'for.providers.join.aepsy.section.step.four.subtitle'
                                ),
                            },
                            {
                                icon: '🚀',
                                title: t(
                                    'for.providers.join.aepsy.section.step.five.title'
                                ),
                                subtitle: t(
                                    'for.providers.join.aepsy.section.step.five.subtitle'
                                ),
                            },
                            {
                                icon: '💌',
                                title: t(
                                    'for.providers.join.aepsy.section.step.six.title'
                                ),
                                subtitle: t(
                                    'for.providers.join.aepsy.section.step.six.subtitle'
                                ),
                            },
                        ]}
                    />

                    <Section spacingBottom="s" container="large">
                        <div id="provider-signup">
                            <RequirementToJoin />
                        </div>
                    </Section>

                    <Section container="short" spacingTop="m" spacingBottom="m">
                        <TitleGroup
                            title={t('faq.section.title')}
                            subtitle={t('faq.section.subtitle')}
                            align="desktopCenter"
                        />
                        <Section spacing="s">
                            <SingleAccordion data={faq} comingFrom="buchen" />
                        </Section>
                    </Section>

                    {/* Footer */}
                    <Footer />
                </div>
            </div>
        </Layout>
    );
};

export default withLocale(ForTherapistPage);
